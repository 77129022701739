/* C L A S S */

/* Form */
.form {
    width: 100%;
    height: 100%;

    align-items: center;
    display: flex;
    justify-content: center;

    position: relative;
}
.form p,
.form textarea {
    text-align: center;
    white-space: pre;
}
.form p {
    margin: 0;
}
.form textarea {
    background: transparent;
    border: none;
    outline: none;
    padding: 0;
    width: 100%;

    overflow-y: hidden;
    resize: none;
}