/* U N I Q U E */

/* Editor Menu */
#editor-menu {
    position: relative;
    z-index: 1;
}
#editor-menu ul {
    padding: 0;

    display: flex;
    justify-content: center;
    list-style-type: none;

    margin: 0;
}
#editor-menu ul li {
    width: 60px;
    height: 60px;

    position: relative;
}
#editor-menu ul li svg {
    position: absolute;
}
#editor-menu ul li .icon {
    z-index: 1;
}

/* Q U E R Y */

/* Desktop */
@media (min-width: 701px) {
    #editor-menu {
        margin: 10px 0 0;
    }
    #editor-menu ul li {
        cursor: hand;
        cursor: pointer;
        padding: 0 10px;
    }
    #editor-menu ul li .icon {
        padding: 14px;
        width: 32px;
    }
    #editor-menu ul li .shape {
        padding: 0;
        width: 60px;
    }
}

/* Mobile */
@media (max-width: 700px) {
    #editor-menu ul li {
        padding: 0 5px;
    }
    #editor-menu ul li .icon {
        padding: 18px;
        width: 24px;
    }
    #editor-menu ul li .shape {
        padding: 7px;
        width: 46px;
    }
}