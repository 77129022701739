/* U N I Q U E */

/* Editor */
#editor {
    display: grid;
    grid-template-columns: auto;
}
#editor-from,
#editor-tool {
    overflow: hidden;
}

/* Q U E R Y */

/* Desktop */
@media (min-width: 701px) {
    #editor-form,
    #editor-tool {
        width: 35vh;
        max-width: 320px;
        min-width: 240px;
        height: 35vh;
        max-height: 320px;
        min-height: 240px;

        grid-area: 1 / 1 / 2 / 2;

        position: relative;
    }
    #editor-form {
        left: calc(-50% - 10px);
    }
    #editor-tool {
        left: calc(50% + 10px);
    }
}

/* Desktop, align to center */
@media (min-width: 701px) and (min-height: 491px) {
    #editor {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

/* Desktop, align to top */
@media (min-width: 701px) and (max-height: 490px) {
    #editor {
        position: absolute;
        top: 90px;
        left: 50%;
        transform: translate(-50%, 0);
    }
}

/* Mobile */
@media (max-width: 700px) {
    #editor {
        width: calc(100vw - 30px);
        height: calc(100vh - 110px);
        min-height: 125vw;
        
        grid-template-rows: 80vw 60px auto;

        margin: 0 auto;
    }
}