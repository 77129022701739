/* E L E M E N T */

/* Body */
body {
    color: black;
    font-family: 'Lucida Console', Monaco, monospace;
    font-size: 9px;
    line-height: 14px;

    background: #e0e0e0;
    padding: 0;
    
    overflow-x: hidden;
    overflow-y: auto;

    margin: 0;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* F O N T */

@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');

/* U N I Q U E */

/* App */
#app {
    width: 100vw;
    min-height: 100vh;
}