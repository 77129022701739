@import url(https://fonts.googleapis.com/css2?family=Nunito&display=swap);
/* U N I Q U E */

/* Editor */
#editor {
    display: grid;
    grid-template-columns: auto;
}
#editor-from,
#editor-tool {
    overflow: hidden;
}

/* Q U E R Y */

/* Desktop */
@media (min-width: 701px) {
    #editor-form,
    #editor-tool {
        width: 35vh;
        max-width: 320px;
        min-width: 240px;
        height: 35vh;
        max-height: 320px;
        min-height: 240px;

        grid-area: 1 / 1 / 2 / 2;

        position: relative;
    }
    #editor-form {
        left: calc(-50% - 10px);
    }
    #editor-tool {
        left: calc(50% + 10px);
    }
}

/* Desktop, align to center */
@media (min-width: 701px) and (min-height: 491px) {
    #editor {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
    }
}

/* Desktop, align to top */
@media (min-width: 701px) and (max-height: 490px) {
    #editor {
        position: absolute;
        top: 90px;
        left: 50%;
        -webkit-transform: translate(-50%, 0);
                transform: translate(-50%, 0);
    }
}

/* Mobile */
@media (max-width: 700px) {
    #editor {
        width: calc(100vw - 30px);
        height: calc(100vh - 110px);
        min-height: 125vw;
        
        grid-template-rows: 80vw 60px auto;

        margin: 0 auto;
    }
}
/* U N I Q U E */

/* Editor Form */
#editor-form {
    width: 100%;
    height: 100%;

    align-items: center;
    display: flex;
    justify-content: center;

    position: relative;
}
#editor-form textarea {
    text-align: center;
    white-space: pre;
    
    background: transparent;
    border: none;
    outline: none;
    padding: 0;
    width: 100%;

    overflow-y: hidden;
    resize: none;
}
/* U N I Q U E */

/* Editor Menu */
#editor-menu {
    position: relative;
    z-index: 1;
}
#editor-menu ul {
    padding: 0;

    display: flex;
    justify-content: center;
    list-style-type: none;

    margin: 0;
}
#editor-menu ul li {
    width: 60px;
    height: 60px;

    position: relative;
}
#editor-menu ul li svg {
    position: absolute;
}
#editor-menu ul li .icon {
    z-index: 1;
}

/* Q U E R Y */

/* Desktop */
@media (min-width: 701px) {
    #editor-menu {
        margin: 10px 0 0;
    }
    #editor-menu ul li {
        cursor: hand;
        cursor: pointer;
        padding: 0 10px;
    }
    #editor-menu ul li .icon {
        padding: 14px;
        width: 32px;
    }
    #editor-menu ul li .shape {
        padding: 0;
        width: 60px;
    }
}

/* Mobile */
@media (max-width: 700px) {
    #editor-menu ul li {
        padding: 0 5px;
    }
    #editor-menu ul li .icon {
        padding: 18px;
        width: 24px;
    }
    #editor-menu ul li .shape {
        padding: 7px;
        width: 46px;
    }
}

/* C L A S S */

/* Editor Tool Menu */
#editor-tool-menu {
    position: relative;
    z-index: 1;
}
#editor-tool-menu ul {
    margin: 0;

    display: flex;
    justify-content: center;
    list-style-type: none;

    padding: 0;
}
#editor-tool-menu ul li {
    display: flex;
    justify-content: center;
}

/* R E S P O N S I V E */

/* Desktop */
@media (min-width: 701px) {
    #editor-tool-menu ul li {
        cursor: hand;
        cursor: pointer;
        width: 60px;
        height: 60px;
    }
    #editor-tool-menu ul li svg {
        width: 32px;
    }
}

/* Mobile */
@media (max-width: 700px) {
    #editor-tool-menu ul li {
        width: 50px;
        height: 50px;
    }
    #editor-tool-menu ul li svg {
        width: 24px;
    }
}
/* U N I Q U E */

/* Editor Tool List */
#editor-tool-list {
    flex: 1 1;
}
#editor-tool-list ul {
    margin: 0;

    list-style-type: none;

    padding: 0;
}
#editor-tool-list ul li {
    position: relative;

    -webkit-user-select: none;

        -ms-user-select: none;

            user-select: none;
}
#editor-tool-list ul li .check {
    width: 24px;
    height: 24px;

    position: absolute;
    top: -5px;
    right: -5px;
    z-index: 3;
}
#editor-tool-list ul li .color,
#editor-tool-list ul li .image {
    width: 100%;
    height: 100%;

    position: absolute;
    top: 0;
    left: 0;
}
#editor-tool-list ul li .glyph {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: 2;
}
#editor-tool-list ul li .image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: 200%;

    z-index: 1;
}

/* Q U E R Y */

/* Desktop */
@media (min-width: 701px) {
    #editor-tool-list {
        overflow-x: hidden;
        overflow-y: scroll;

        margin: 0 20px;
    }
    #editor-tool-list ul {
        padding: 20px 10px 20px 0;

        display: grid;
        grid-gap: 10px;
        gap: 10px; 
        grid-template-columns: repeat(3, auto);
    }
    #editor-tool-list ul li {
        cursor: hand;
        cursor: pointer;
        padding: 100% 0 0;
        width: 100%;
    }
}

/* Mobile */
@media (max-width: 700px) {
    #editor-tool-list {
        overflow-x: scroll;
        overflow-y: hidden;
    }
    #editor-tool-list::-webkit-scrollbar,
    #editor-tool-list::-webkit-scrollbar-thumb,
    #editor-tool-list::-webkit-scrollbar-track {
        background: transparent;
        height: 0;
        opacity: 0;
    }
    #editor-tool-list ul {
        padding: 15px 15px 0;

        display: inline-grid;
        grid-gap: 15px;
        gap: 15px;
    }
}
/* E L E M E N T */

/* Footer */
footer {
    display: flex;
    justify-content: space-between;
}
footer span {
    font-family: 'Nunito', sans-serif;
    font-size: 18px;
    font-style: normal;
    line-height: 20px;

    display: block;
}

/* R E S P O N S I V E */

/* Desktop */
@media (min-width: 701px) {
    footer {
        padding: 20px;
        width: calc(100% - 40px);

        position: absolute;
        bottom: 0;
        left: 0;
    }
}

/* Mobile */
@media (max-width: 700px) {
    footer {
        padding: 15px;
        width: calc(100% - 30px);
    }
}
/* E L E M E N T */

/* Header */
header {
    display: flex;
    justify-content: space-between;
}
header image,
header svg {
    height: 40px;
}

/* R E S P O N S I V E */

/* Desktop */
@media (min-width: 701px) {
    header {
        padding: 15px 20px;
        width: calc(100% - 40px);
    }
}

/* Mobile */
@media (max-width: 700px) {
    header {
        padding: 10px 15px;
        width: calc(100% - 30px);
    }
}
/* C L A S S */

/* Canvas */
.canvas {
    position: relative;
    z-index: -1;
}
.canvas canvas,
.canvas div {
    position: absolute;
    top: 0;
    left: 0;
}
.canvas-class {
    z-index: 1;
}
.canvas-color {
    z-index: 0;
}
.canvas-image {
    z-index: 2;
}
/* U N I Q U E */

/* Render */
#render {
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;

    pointer-events: none;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}
/* U N I Q U E */

/* Status */
#status {
    overflow-x: hidden;

    pointer-events: none;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}
#status ul {
    -webkit-mask: linear-gradient(90deg, rgb(0, 0, 0, 1) 80%, rgba(0, 0, 0, 0) 100%);
            mask: linear-gradient(90deg, rgb(0, 0, 0, 1) 80%, rgba(0, 0, 0, 0) 100%);
    width: 200px;
    padding: 0;

    list-style-type: none;
    overflow: hidden;

    margin: 0 0 6px;
}
#status ul:before {
    content: attr(aria-label);
    font-weight: 700;
    white-space: nowrap;
}
#status ul li {
    white-space: nowrap;

    opacity: 0.8;
}
#status ul li figure {
    border-radius: 50%;
    width: 10px;
    height: 10px;

    display: inline-block;

    margin: 0 2px 0 0;
    position: relative;
    top: 2px;
}
/* E L E M E N T */

/* Body */
body {
    color: black;
    font-family: 'Lucida Console', Monaco, monospace;
    font-size: 9px;
    line-height: 14px;

    background: #e0e0e0;
    padding: 0;
    
    overflow-x: hidden;
    overflow-y: auto;

    margin: 0;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* F O N T */

/* U N I Q U E */

/* App */
#app {
    width: 100vw;
    min-height: 100vh;
}
/* C L A S S */

/* Form */
.form {
    width: 100%;
    height: 100%;

    align-items: center;
    display: flex;
    justify-content: center;

    position: relative;
}
.form p,
.form textarea {
    text-align: center;
    white-space: pre;
}
.form p {
    margin: 0;
}
.form textarea {
    background: transparent;
    border: none;
    outline: none;
    padding: 0;
    width: 100%;

    overflow-y: hidden;
    resize: none;
}
