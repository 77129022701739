/* E L E M E N T */

/* Header */
header {
    display: flex;
    justify-content: space-between;
}
header image,
header svg {
    height: 40px;
}

/* R E S P O N S I V E */

/* Desktop */
@media (min-width: 701px) {
    header {
        padding: 15px 20px;
        width: calc(100% - 40px);
    }
}

/* Mobile */
@media (max-width: 700px) {
    header {
        padding: 10px 15px;
        width: calc(100% - 30px);
    }
}