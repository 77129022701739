/* C L A S S */

/* Canvas */
.canvas {
    position: relative;
    z-index: -1;
}
.canvas canvas,
.canvas div {
    position: absolute;
    top: 0;
    left: 0;
}
.canvas-class {
    z-index: 1;
}
.canvas-color {
    z-index: 0;
}
.canvas-image {
    z-index: 2;
}