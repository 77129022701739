/* C L A S S */

/* Editor Tool Menu */
#editor-tool-menu {
    position: relative;
    z-index: 1;
}
#editor-tool-menu ul {
    margin: 0;

    display: flex;
    justify-content: center;
    list-style-type: none;

    padding: 0;
}
#editor-tool-menu ul li {
    display: flex;
    justify-content: center;
}

/* R E S P O N S I V E */

/* Desktop */
@media (min-width: 701px) {
    #editor-tool-menu ul li {
        cursor: hand;
        cursor: pointer;
        width: 60px;
        height: 60px;
    }
    #editor-tool-menu ul li svg {
        width: 32px;
    }
}

/* Mobile */
@media (max-width: 700px) {
    #editor-tool-menu ul li {
        width: 50px;
        height: 50px;
    }
    #editor-tool-menu ul li svg {
        width: 24px;
    }
}