/* E L E M E N T */

/* Footer */
footer {
    display: flex;
    justify-content: space-between;
}
footer span {
    font-family: 'Nunito', sans-serif;
    font-size: 18px;
    font-style: normal;
    line-height: 20px;

    display: block;
}

/* R E S P O N S I V E */

/* Desktop */
@media (min-width: 701px) {
    footer {
        padding: 20px;
        width: calc(100% - 40px);

        position: absolute;
        bottom: 0;
        left: 0;
    }
}

/* Mobile */
@media (max-width: 700px) {
    footer {
        padding: 15px;
        width: calc(100% - 30px);
    }
}