/* U N I Q U E */

/* Editor Tool List */
#editor-tool-list {
    flex: 1;
}
#editor-tool-list ul {
    margin: 0;

    list-style-type: none;

    padding: 0;
}
#editor-tool-list ul li {
    position: relative;

    user-select: none;
}
#editor-tool-list ul li .check {
    width: 24px;
    height: 24px;

    position: absolute;
    top: -5px;
    right: -5px;
    z-index: 3;
}
#editor-tool-list ul li .color,
#editor-tool-list ul li .image {
    width: 100%;
    height: 100%;

    position: absolute;
    top: 0;
    left: 0;
}
#editor-tool-list ul li .glyph {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
}
#editor-tool-list ul li .image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: 200%;

    z-index: 1;
}

/* Q U E R Y */

/* Desktop */
@media (min-width: 701px) {
    #editor-tool-list {
        overflow-x: hidden;
        overflow-y: scroll;

        margin: 0 20px;
    }
    #editor-tool-list ul {
        padding: 20px 10px 20px 0;

        display: grid;
        gap: 10px; 
        grid-template-columns: repeat(3, auto);
    }
    #editor-tool-list ul li {
        cursor: hand;
        cursor: pointer;
        padding: 100% 0 0;
        width: 100%;
    }
}

/* Mobile */
@media (max-width: 700px) {
    #editor-tool-list {
        overflow-x: scroll;
        overflow-y: hidden;
    }
    #editor-tool-list::-webkit-scrollbar,
    #editor-tool-list::-webkit-scrollbar-thumb,
    #editor-tool-list::-webkit-scrollbar-track {
        background: transparent;
        height: 0;
        opacity: 0;
    }
    #editor-tool-list ul {
        padding: 15px 15px 0;

        display: inline-grid;
        gap: 15px;
    }
}