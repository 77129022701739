/* U N I Q U E */

/* Status */
#status {
    overflow-x: hidden;

    pointer-events: none;
    user-select: none;
}
#status ul {
    mask: linear-gradient(90deg, rgb(0, 0, 0, 1) 80%, rgba(0, 0, 0, 0) 100%);
    width: 200px;
    padding: 0;

    list-style-type: none;
    overflow: hidden;

    margin: 0 0 6px;
}
#status ul:before {
    content: attr(aria-label);
    font-weight: 700;
    white-space: nowrap;
}
#status ul li {
    white-space: nowrap;

    opacity: 0.8;
}
#status ul li figure {
    border-radius: 50%;
    width: 10px;
    height: 10px;

    display: inline-block;

    margin: 0 2px 0 0;
    position: relative;
    top: 2px;
}