/* U N I Q U E */

/* Editor Form */
#editor-form {
    width: 100%;
    height: 100%;

    align-items: center;
    display: flex;
    justify-content: center;

    position: relative;
}
#editor-form textarea {
    text-align: center;
    white-space: pre;
    
    background: transparent;
    border: none;
    outline: none;
    padding: 0;
    width: 100%;

    overflow-y: hidden;
    resize: none;
}